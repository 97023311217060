import ShipmentActionEnum from './shipmentActionEnum'

export function generateMessages(actionType) {
  let confirmMessages = {
    title: '',
    subtitle: ''
  }

  switch (actionType) {
    case ShipmentActionEnum.ACCEPT:
      confirmMessages.title = 'Aceitar Viagem'
      confirmMessages.subtitle = 'Confirme para aceitar a viagem.'
      break
    case ShipmentActionEnum.REJECT:
      confirmMessages.title = 'Rejeitar viagem'
      confirmMessages.subtitle = 'Confirme para rejeitar a viagem.'
      break

    default:
      confirmMessages.title = 'Ação Desconhecida'
      confirmMessages.subtitle = 'Selecione uma ação válida.'
  }

  return confirmMessages
}
