/**
 * Resize Ag-Grid columns to fit the content o fill all the space
 *
 * @param {import('ag-grid-community').ColumnApi} columnApi Ag-Grid Column API of the table to be resized
 * @param {import('ag-grid-community').ColumnApi} [referenceApi] Ag-Grid Column API of the table used as reference
 */
const resizeColumns = (columnApi, referenceApi = columnApi) => {
    // Auto size all columns to force Ag-Grid to calculate the minimum width
    // for each column in the table
    columnApi.autoSizeAllColumns(false)
  
    // After resizing, if the body width does not fill the table size, scale
    // the columns to take all the space
    const targetWidth = referenceApi.columnController.scrollWidth
    const currentWidth = columnApi.columnController.bodyWidth
  
    if (currentWidth < targetWidth) {
      const scalingFactor = targetWidth / currentWidth
      const columnWidths = columnApi
        .getAllColumns()
        .filter((column) => !column.pinned)
        .map((column) => ({
          key: column.colId,
          newWidth: column.getActualWidth() * scalingFactor
        }))
      columnApi.setColumnWidths(columnWidths)
    }
  }
  
  /**
   * Resize Ag-Grid columns to fit the content o fill all the space
   *
   * @param {*} aggridOptions Ag-Grid Options
   */
  const resize = (aggridOptions) => {
    resizeColumns(aggridOptions.columnApi)
  }
  
  /**
   * Build the default Ag-Grid main menu object
   *
   * @param {import('ag-grid-community').GetMainMenuItemsParams} params Ag-Grid Main menu item params
   * @returns {(import('ag-grid-community').MenuItemDef | string)[]} An array with the filter menu items
   *
   * @see {@link https://www.ag-grid.com/archive/23.2.0/javascript-grid-column-menu ag-Grid Docs}
   */
  const getMainMenuItems = (params) => {
    const translate = params.api.gridOptionsWrapper.getLocaleTextFunc()
  
    return params.defaultItems.map((item) => {
      switch (item) {
        case 'autoSizeAll':
          return {
            name: translate('autosizeAllColumns', 'Autosize All Columns'),
            action: () => resizeColumns(params.columnApi)
          }
  
        default:
          return item
      }
    })
  }
  
  export default {
    resize,
    getMainMenuItems
  }
  