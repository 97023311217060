<template>
  <div>
    <div v-if="metadata != null">
      <div>
        <grid-filter
          class="filter-grid"
          key="componentKey"
          @applyDateFilter="applyDateFilter"
          :fields="dateFilters"
        />
      </div>
      <bc-crud-meta-view
        :service="customService"
        :metadata="metadata"
        :showGridActions="true"
        gridMode="clientSide"
        translate-prefix="application.pages"
      />
    </div>
  </div>
</template>
<script>
import { BcCrudMetaView, CrudAction } from '@brain/core'
import { notify } from '@brain/ui'
import helpers from '../helpers/findComponent'
import { handleError } from '../helpers/errorHandler'
import { shipmentService } from '../services'
import ShipmentStatusEnum from '../utils/shipmentEnum'
import GridFilter from '../components/filter/GridFilter.vue'
import moment from 'moment'
export default {
  name: 'Shipment',
  components: {
    BcCrudMetaView,
    GridFilter
  },
  data: () => ({
    showConfirm: false,
    showAbsenceDialog: false,
    nodeId: null,
    shipmentAbsence: null,
    selectedAction: null,
    metadata: null,
    isAlert: false,
    filterStartDate: null,
    filterEndDate: null,
    confirmMessages: {
      title: '',
      subtitle: ''
    },
    dateFilters: [
      { name: 'startDate', type: 'date', label: 'Data Inicio' },
      { name: 'endDate', type: 'date', label: 'Data Fim' }
    ],
    statusDefinitions: [
      { status: 'shipments', color: '#708090' },
      { status: 'published', color: '#8CB965' },
      { status: 'confirmed', color: '#1B67B3' },
      { status: 'inTraffic', color: '#E19F3B' },
      { status: 'waitingToLoad', color: '#00ABBD' },
      { status: 'waitingToUnload', color: '#0099DD' },
      { status: 'unloading', color: '#7F8C8D      ' },
      { status: 'completed', color: '#026E81' },
      { status: 'miss', color: '#856C47' },
      { status: 'rejected', color: '#B52953' }
    ],
    customService: {
      ...shipmentService
    },
    shipmentService
  }),
  computed: {
    bcGrid() {
      return helpers.findComponent(this, 'BcGrid')
    }
  },
  methods: {
    async applyDateFilter({ startDate, endDate }) {
      if (endDate == null) {
        endDate = startDate
      }
      if (moment(endDate) >= moment(startDate)) {
        this.filterStartDate = moment(startDate).toISOString()
        this.filterEndDate = moment(endDate).toISOString()
        var dateFilter = {
          dateFrom: this.filterStartDate,
          dateTo: this.filterEndDate,
          filterType: 'date',
          type: 'inRange'
        }
        let bcGrid = helpers.findComponent(this, 'BcGrid')
        const filterModel = bcGrid.aggridApi.getFilterModel()
        filterModel.estimatedDeparturePlantTime = dateFilter
        bcGrid.aggridApi.setFilterModel(filterModel)
        bcGrid.aggridApi.onFilterChanged()
      } else {
        this.filterStartDate = null
        this.filterEndDate = null
        notify.error({
          title: 'Data final não pode ser menor que a data inicial'
        })
      }
    },
    async getMeta() {
      this.customService.getMeta = async () => {
        try {
          return await shipmentService.getMetaReport()
        } catch (e) {
          handleError(e)
        }
      }
      const meta = await this.customService.getMetaWithValidation()
      meta.permissions = [CrudAction.EXPORT]
      meta.fields.forEach((field) => {
        if (field.id === 'driver' || field.id === 'vehicle') {
          field.colDefProps = {
            editable: function (params) {
              return params.data.status == ShipmentStatusEnum.CONFIRMED
            }
          }
        }
      })

      this.customService.getAll = async () => {
        try {
          return await shipmentService.getReportShipmentsByUserCompany()
        } catch (e) {
          handleError(e)
        }
      }
      this.customService.export = async (...queryParams) => {
        try {
          return await shipmentService.getExportshipmentByUserCompanyReport(...queryParams
          )
        } catch (e) {
          handleError(e)
        }
      }
      return meta
    },

    reloadGridData() {
      let bcCrudGrid = helpers.findComponent(this, 'BcCrudGrid')
      bcCrudGrid.onReloadHandler()
    }
  },
  watch: {
    isAlert(newValue) {
      if (newValue) {
        notify.error({
          title: this.$t('application.pages.shipment.notifyTitle'),
          description: this.$t('application.pages.shipment.notifyWarning')
        })
      }
    }
  },
  created() {
    const warningCellStyle = {
      border: '1px dashed red',
      color: 'white',
      backgroundColor: '#D2354F1a'
    }

    this.getMeta().then((meta) => {
      const vehicle = meta.fields.find((res) => res.id === 'vehicle')
      vehicle.colDefProps = {
        cellStyle: (params) => {
          let cellStyle = {}
          if (
            params.data.vehicle.text === '' &&
            params.data.status === ShipmentStatusEnum.CONFIRMED
          ) {
            this.isAlert = true
            cellStyle = warningCellStyle
          } else {
            cellStyle = { backgroundColor: 'transparent' }
          }
          return cellStyle
        }
      }

      const driver = meta.fields.find((res) => res.id === 'driver')
      driver.colDefProps = {
        cellStyle: (params) => {
          let cellStyle = {}
          if (
            params.data.driver.text === undefined &&
            params.data.status === ShipmentStatusEnum.CONFIRMED
          ) {
            this.isAlert = true
            cellStyle = warningCellStyle
          } else {
            cellStyle = { backgroundColor: 'transparent' }
          }
          return cellStyle
        }
      }

      const statusColors = this.statusDefinitions.reduce(
        (acc, { status, color }) => {
          acc[status] = color
          return acc
        },
        {}
      )

      const shipment = meta.fields.find((res) => res.id === 'status')
      shipment.colDefProps = {
        cellStyle: (params) => {
          const status = params.data.status
          const color = statusColors[status]

          return { color: `${color} !important` }
        }
      }

      this.metadata = meta
    })
  }
}
</script>
<style lang="scss" scoped>
.button-header-container1 {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0.7rem;
}
.filter-grid {
  margin-left: 20px;
  width: 70%;
  z-index: 1;
}
.filter-container {
  display: flex;
  margin-top: 16px;
  width: 40%;

  & > :not(:first-child) {
    margin-left: 16px;
  }
}
::v-deep .main-toolbar {
  min-height: 0px !important;
}
</style>
