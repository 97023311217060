import helpers from './findComponent.js'
import _ from 'lodash'
import BrainCore from '../boot/brainCore.js'
import { responseToOptions } from '../boot/baseApi.js'

const findComponentPolling = helpers.findComponentPolling

const languages = ['en', 'pt-BR']

const updateOptions = async (componentRoot, fieldMeta, fieldEdit, options) => {
  const formField = await findFormField(componentRoot, fieldMeta)
  formField.options = options ?? []
  if (fieldEdit != null && !formField.options?.includes(fieldEdit.value)) {
    fieldEdit.value = null
  }
}

const updateOptionToNull = (itemData, fieldOptions) => {
  const options = fieldOptions.options({ itemData: itemData })
  const fieldEdit = itemData[fieldOptions.id]
  if (fieldEdit != null && !options?.includes(fieldEdit.value)) {
    fieldEdit.value = null
  }
}

const updateOptionToNullOnValueChange = (dependency, fieldOptions) => {
  dependency.onValueChange = (changedValue, fields) => {
    updateOptionToNull(fields, fieldOptions)
  }
}

async function findFormField(componentRoot, fieldMeta) {
  const form = await findComponentPolling(componentRoot, 'BcCrudForm')
  return form.itemData[fieldMeta.id]
}

const checkRequiredDefault = (value) => {
  return (
    value != null &&
    value != '' &&
    (!_.isObject(value) || value?.value == null || value?.value == '')
  )
}

const checkRequiredArgsDefault = (...args) => {
  if (!_.isArray(args)) {
    const error = 'ERROR on checkRequiredArgsDefault args=' + args
    console.log(error)
    throw error
  }
  return args.some((x) => checkRequiredDefault(x))
}

const includeImpactedRequired = (
  requiredMappings,
  validationSchema,
  translate,
  checkRequiredArgs = checkRequiredArgsDefault
) => {
  Object.entries(requiredMappings).forEach(([impactedField, fields]) => {
    const impactedSchema = validationSchema[impactedField]
    validationSchema[impactedField] = impactedSchema.when(fields, {
      is: checkRequiredArgs,
      then: impactedSchema.required().label(translate(impactedField)),
      otherwise: impactedSchema
    })
  })
}

const defineTakeUpLocality = (
  cityByState,
  fieldsById,
  stateFieldName,
  cityFieldName
) => {
  Object.keys(cityByState).forEach((x) => {
    cityByState[x] = responseToOptions(
      cityByState[x],
      (id, options) => options[id]
    )
  })

  fieldsById[stateFieldName].onValueChange = (changedValue, fields) => {
    const state = changedValue?.label
    const cityOptions = cityByState[String(state).toLocaleLowerCase()] ?? []
    if (
      !cityOptions.some((x) => x.value == fields[cityFieldName].value?.value)
    ) {
      fields[cityFieldName].value = null
    }
  }

  fieldsById[cityFieldName].options = async ({ itemData }) => {
    const state = itemData[stateFieldName]
    return cityByState[String(state).toLocaleLowerCase()] ?? []
  }
  fieldsById[cityFieldName].returnObject = false
}

const createBaseLocalityValidations = (
  validationSchema,
  translate,
  fields = localityFieldsDefault
) => {
  const requiredMappings = Object.fromEntries([
    [fields.state, fields.city],
    [fields.country, [fields.state, fields.city]],
    [fields.region, [fields.country, fields.state, fields.city]]
  ])
  includeImpactedRequired(requiredMappings, validationSchema, translate)
}

const localityFieldsArray = [
  'region',
  'country',
  'state',
  'city',
  'longitude',
  'latitude'
]

const getLocalityFields = () => [...localityFieldsArray]

const localityFieldsDefault = {}
localityFieldsArray.forEach((f) => (localityFieldsDefault[f] = f))

const createLocalityValidations = (
  validationSchema,
  translate,
  fields = localityFieldsDefault
) => {
  createBaseLocalityValidations(validationSchema, translate, fields)
  const requiredMappings = Object.fromEntries([
    [fields.longitude, fields.latitude],
    [fields.latitude, fields.longitude]
  ])
  includeImpactedRequired(requiredMappings, validationSchema, translate)
}

const updateOptionsRefs = (realOptions, optionsByKey) => {
  const realOptionById = _.keyBy(realOptions, (x) => x.value)
  _.forIn(optionsByKey, (options, key) => {
    optionsByKey[key] = _.sortBy(
      options.map((option) => realOptionById[option]),
      (x) => x.label
    )
  })
}

const updateOptionRefByKey = (realOptions, optionByKey) => {
  const realOptionById = _.keyBy(realOptions, (x) => x.value)
  for (const key of _.keys(optionByKey)) {
    optionByKey[key] = realOptionById[optionByKey[key]]
  }
}

const createEnumOption = (field, option, getEnumI18NLabel) => {
  if (option instanceof Object) {
    return option
  } else {
    return {
      label: getEnumI18NLabel(field, option),
      text: getEnumI18NLabel(field, option),
      value: option,
      toString() {
        return this.text
      }
    }
  }
}

const getEnumValue = (value) => {
  if (_.isObject(value)) {
    return value?.value
  } else {
    return value
  }
}

const getMessageColor = (_type) => {
  if (_type == 'warning') {
    return '#E2A13D'
  } else if (_type == 'error') {
    return '#D2354F'
  } else if (_type == 'information') {
    return '#355790'
  }
}

const getMessageIcon = (_type) => {
  if (_type == 'warning') {
    return 'fas fa-exclamation-triangle'
  } else if (_type == 'error') {
    return 'fas fa-times-hexagon'
  } else if (_type == 'information') {
    return 'fas fa-info-circle'
  }
}

const createEnumOptions = (field, options, getEnumI18NLabel) =>
  _.isArray(options)
    ? options.map((option) => createEnumOption(field, option, getEnumI18NLabel))
    : []

const processMetaDetails = (meta, name, masterName, masterField, service) => {
  meta.name = name
  meta.masterName = masterName
  meta.fields = meta.fields.filter((field) => field.id != masterField)
  meta.detailsObject = {
    service: service,
    meta: meta
  }
}

const createDetailsObject = (meta, translatePrefix = 'application.pages') => {
  const details = {}
  if (meta.details != null) {
    processFixedColumns(meta, translatePrefix)
    meta.details.forEach((detail) => {
      details[detail.name] = detail.detailsObject
    })
  }
  return details
}


const createPropertyAccess = (propertyPath) => {
  const prefixes = propertyPath.split('.')
  return (obj) =>
    prefixes.reduce((a, b) => {
      if (a[b] == null) {
        a[b] = {}
      }
      return a[b]
    }, obj)
}

const createTranslateFixedColumn = (
  masterName,
  oldDetailName,
  newMetaDetail,
  newTranslateObj,
  translatePrefix,
  accessTranslatePrefix
) => {
  const accessTranslateDefaultPrefix = createPropertyAccess('application.pages')
  const createMasterDetailAcess = createPropertyAccess(
    `${masterName}.${newMetaDetail.id}`
  )
  languages.forEach((locale) => {
    if (newTranslateObj[locale] == null) {
      newTranslateObj[locale] = {}
    }
    var translateObjPrefix = createMasterDetailAcess(
      accessTranslatePrefix(newTranslateObj[locale])
    )
    var translateObjDefaultPrefix = createMasterDetailAcess(
      accessTranslateDefaultPrefix(newTranslateObj[locale])
    )
    translateObjDefaultPrefix.title = newMetaDetail.nameI18N
    translateObjDefaultPrefix[newMetaDetail.id] = {
      title: newMetaDetail.nameI18N
    }
    translateObjPrefix.title = newMetaDetail.nameI18N

    newMetaDetail.fields.forEach((field) => {
      translateObjPrefix[field.id] = BrainCore.i18n.t(
        `${translatePrefix}.${masterName}.${oldDetailName}.${field.id}`
      )
    })
  })
}

const processFixedColumns = (meta, translatePrefix) => {
  if (_.isEmpty(meta.fixedColumnContexts) || _.isEmpty(meta.details)) {
    return
  }
  const fixedColumnCtxByNameLower = Object.fromEntries(
    meta.fixedColumnContexts.map((ctx) => [ctx.typeName.toLowerCase(), ctx])
  )
  const accessTranslatePrefix = createPropertyAccess(translatePrefix)
  const newTranslateObj = {}
  const newDetails = []
  meta.details.forEach((detail) => {
    const fixedColumn = fixedColumnCtxByNameLower[detail.name.toLowerCase()]
    if (fixedColumn == null) {
      newDetails.push(detail)
    } else {
      const ignoredFields = new Set(
        fixedColumn.ignoredFields.map((field) => _.camelCase(field))
      )
      const newFields = detail.fields.filter(
        (field) => !ignoredFields.has(field.id)
      )
      var originalService = detail.detailsObject.service
      fixedColumn.contexts.forEach((ctx) => {
        const newDetail = { ...detail }
        newDetail.fields = _.cloneDeep(newFields)
        newDetail.id = newDetail.name = _.camelCase(ctx.code)
        newDetail.nameI18N = ctx.name
        newDetail.detailsObject = {
          service: originalService.createServiceFixedColumn(ctx),
          meta: newDetail
        }
        var newService = newDetail.detailsObject.service
        if (originalService.processValidationContext != null) {
          newService.processValidationContext =
            originalService.processValidationContext.bind(newService)
          try {
            newService.processValidationContext(
              null,
              newDetail.validationCtx,
              newDetail,
              newService
            )
          } catch (exception) {
            console.error(exception)
          }
        }
        createTranslateFixedColumn(
          meta.id,
          detail.id,
          newDetail,
          newTranslateObj,
          translatePrefix,
          accessTranslatePrefix
        )
        newDetails.push(newDetail)
      })
    }
  })
  BrainCore.i18n.registerLocale(newTranslateObj)
  meta.details = newDetails
}

const setCookie = (cName, cValue, expires = 'Session', path = '/logistica') => {
  document.cookie = `${cName}=${cValue}; expires=${expires}; path=${path}`
}

const createConfirmPromise = async (component, config, bypassFunc) => {
  return new Promise((resolve) => {
    if (bypassFunc != null && bypassFunc()) {
      return resolve(true)
    }
    component.$brain.confirm(
      config,
      () => resolve(true),
      () => resolve(false)
    )
  })
}

export default {
  updateOptions,
  includeImpactedRequired,
  createBaseLocalityValidations,
  createLocalityValidations,
  getLocalityFields,
  checkRequiredDefault,
  updateOptionsRefs,
  updateOptionRefByKey,
  createEnumOptions,
  processMetaDetails,
  createDetailsObject,
  updateOptionToNull,
  updateOptionToNullOnValueChange,
  processFixedColumns,
  getMessageColor,
  getMessageIcon,
  getEnumValue,
  setCookie,
  createConfirmPromise,
  defineTakeUpLocality
}
