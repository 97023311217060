<template>
  <div class="filter-container">
    <template v-for="field in fields">
      <bc-date-field v-if="field.type == 'date'" :key="field.name" :filled="false" v-bind="field"
        v-model="values[field.name]">
      </bc-date-field>
      <bc-select v-if="field.type == 'select'" :key="field.name" :filled="false" v-bind="field"
        v-model="values[field.name]">
      </bc-select>
    </template>

    <bc-btn style="margin-left: -2px" color="link-blue" @click="applyFilter()">Aplicar</bc-btn>
  </div>
</template>

<script>
import { BcDatePicker, BcTextField, BcMenu, BcFormInput } from '@brain/core'
export default {
  components: { BcDatePicker, BcTextField, BcMenu, BcFormInput },
  data: () => ({
    date_start: null,
    date_end: null,
    blast_furnace: null,
    menu_start: false,
    menu_end: false,
    menu_blast_furnace: false,
    values: {}
  }),

  props: {
    fields: {
      type: Array,
      default: () => [
        { name: 'startDate', type: 'date', label: 'Data Inicio' },
        { name: 'endDate', type: 'date', label: 'Data Fim' }
      ]
    }
  },

  methods: {
    applyFilter() {
      this.$emit('applyDateFilter', this.values)
    }
  }
}
</script>
