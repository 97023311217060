<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <Bc-Card>
        <Bc-Container>
          <v-form>
            <bc-row>
              <bc-col>
                <bc-textarea
                  @input="description = $event"
                  :filled="filled"
                  :label="
                    $t(
                      'application.pages.shipmentsByCompany.throwShipmentAbsenceDescription'
                    )
                  "
                  :value="description"
                  :hint="
                    $t(
                      'application.pages.shipmentsByCompany.throwShipmentAbsenceDescriptionHint'
                    )
                  "
                ></bc-textarea>
              </bc-col>
            </bc-row>
            <Bc-Btn
              type="submit"
              color="primary"
              class="mr-4"
              @click.prevent="throwAbsence"
            >
              {{ $t('core.misc.save') }}
            </Bc-Btn>
          </v-form>
        </Bc-Container>
      </Bc-Card>
    </v-dialog>
  </div>
</template>

<script>
import { shipmentAbsenceService } from '../services'
import { handleError } from '../helpers/errorHandler'
import { notify } from '@brain/ui'

export default {
  name: 'ThrowShipmentAbsence',
  props: { shipment: { default: null, type: Object } },
  data: () => ({
    filled: false,
    dialog: true,
    description: ''
  }),
  methods: {
    async throwAbsence() {
      try {
        const response = await shipmentAbsenceService
          .saveShipmentAbsence(this.shipment.id, this.description)
          .finally(() => {
            this.dialog = false
            notify.success({
              title: this.$t('application.pages.shipmentsByCompany.success'),
              description: this.$t(
                'application.pages.shipmentsByCompany.absenceTrackingSuccess'
              )
            })
          })
        if (response != null) {
          this.$emit('closeAbsenceDialog')
        }
      } catch (e) {
        handleError(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
