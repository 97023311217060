import { AuthService } from '@brain/core'

const authService = new AuthService({
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
  url: process.env.VUE_APP_KEYCLOAK_URI,
  customLogoutUrl: '/logout'
})

export default authService
