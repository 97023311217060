import { createCrudRoutes } from '@brain/crud'
import VehicleOffer from '../views/VehicleOffer.vue'
import Shipment from '../views/Shipment.vue'
import Driver from '../views/Driver.vue'
import Vehicle from '../views/Vehicle.vue'
import ShipmentReport from '../views/ShipmentReport.vue'
import TruckTractor from '../views/TruckTractor.vue'
export default [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "main" */ '../layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        title: 'application.pages.home.title',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      ...createCrudRoutes('vehicle-offer', VehicleOffer, ['vehicleOffer']),
      ...createCrudRoutes('shipment', Shipment, ['shipment']),
      ...createCrudRoutes('vehicle', Vehicle, ['vehicle']),
      ...createCrudRoutes('driver', Driver, ['Driver']),
      ...createCrudRoutes('shipment-Report', ShipmentReport, ['shipmentReport']),
      ...createCrudRoutes('truck-tractor', TruckTractor, ['truckTractor'])
    ]
  }
]
