import { defineStore } from 'pinia'
import authService from '@/boot/authService'

export const useTransactions = defineStore('transaction', {
  state: () => ({
    /**
     * User transactions object
     *
     * @example <caption>Example of transaction object</caption>
     * transaction: {
     *   user: {
     *     create: true,
     *     read: true,
     *     update: true,
     *     delete: true
     *   },
     *   // ...
     * }
     */
    transactions: {}
  }),

  getters: {
    /**
     * Determines whether the current user has permission to create the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canCreate: (state) => (transaction) =>
      !!state.transactions[transaction]?.create,

    /**
     * Determines whether the current user has permission to read the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canRead: (state) => (transaction) =>
      !!state.transactions[transaction]?.read,

    /**
     * Determines whether the current user has permission to update the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canUpdate: (state) => (transaction) =>
      !!state.transactions[transaction]?.update,

    /**
     * Determines whether the current user has permission to delete the
     * `transaction` resource.
     *
     * @param {String} transaction The resource to check
     */
    canDelete: (state) => (transaction) =>
      !!state.transactions[transaction]?.delete
  },

  actions: {
    /**
     * Fetch the user transaction list
     */
    fetchTransactions() {
      this.transactions = {
        User: {
          create: true,
          read: true,
          update: true,
          delete: true
        },
      }
      let tokenParsed = authService.kc.tokenParsed
      tokenParsed?.realm_access?.roles?.forEach(element => {
        if (element == "SmartBio - Administrador"){
          this.transactions.SmartbioParameter = {
            create: true,
            read: true,
            update: true,
            delete: true
          }
        }
      })
    },

    /**
     * Update the user transaction list
     *
     * This method is for tests purpose only
     */
    updateTransactions(transactions) {
      this.transactions = transactions
    }
  }
})
