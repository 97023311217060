import { createCrudService } from '../boot/gerdauCrudService'
import api from '../boot/api'
import { responseToOptionsInContext } from '../boot/baseApi'
import { handleError } from '../helpers/errorHandler'
const basePath = '/logisticaApi/driver'
const driverService = createCrudService(basePath, api)

export default driverService
driverService.basePath = basePath
driverService.getDriversByUserCompany = async () => {
  let result = await api.get(`${basePath}/getDriversByUserCompany`)
  const data = Array.isArray(result.data.data) ? result.data.data : result.data
  return data
}

driverService.getExportVehicleByUserCompany = async () => {
  try {
    let result = await api.get(`${basePath}/exportByCompany`, {
      responseType: 'blob'
    })
    const filename = driverService.getResponseFilenameWithout(result)

    return { data: result.data, filename }
  } catch (e) {
    handleError(e)
    throw e
  }
}
driverService.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.company.options = ctx.companyOptions
}
