import { createCrudService } from '../boot/gerdauCrudService'
import api from '../boot/api'
import _ from 'lodash'
import { responseToOptionsInContext } from '../boot/baseApi'
import { handleError } from '../helpers/errorHandler'
const basePath = '/logisticaApi/shipmentsByCompany'
const shipmentService = createCrudService(basePath, api)
import { CrudAction } from '@brain/core'
export default shipmentService
shipmentService.basePath = basePath
shipmentService.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.driver.options = ctx.driverOptions
  fieldsById.vehicle.options = ctx.vehicleOptions
  fieldsById.truckTractor.options = ctx.truckTractorOptions
  fieldsById.executionPlanId.hide = true
}

shipmentService.getPendingShipmentsByUserCompany = async () => {
  let result = await api.get(`${basePath}/getPendingShipmentsByUserCompany`)
  const data = Array.isArray(result.data.data) ? result.data.data : result.data
  return data
}
shipmentService.getReportShipmentsByUserCompany = async () => {
  let result = await api.get(`${basePath}/getReportShipmentsByUserCompany`)
  const data = Array.isArray(result.data.data) ? result.data.data : result.data
  return data
}
shipmentService.getMetaReport = async () => {
  let response = await api.get(`${basePath}/getMetaReport`)
  const meta = response?.data
  if (shipmentService.postProcessMeta) {
    shipmentService.postProcessMeta(meta)
  }
  return meta
}
shipmentService.sendAcceptedShipmentIds = async (ids) => {
  try {
    const response = await api.post(`${basePath}/acceptSelectedShipments`, ids)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
shipmentService.getExportshipmentByUserCompany = async () => {
  try {
    let result = await api.get(`${basePath}/exportByCompany`, {
      responseType: 'blob'
    })
    const filename = shipmentService.getResponseFilenameWithout(result)

    return { data: result.data, filename }
  } catch (e) {
    handleError(e)
    throw e
  }
}
shipmentService.getExportshipmentByUserCompanyReport = async (
  ...queryParams
) => {
  queryParams = shipmentService.applyTabNameQuery(queryParams)
  queryParams = shipmentService.applyFixedColumnsFilter(queryParams)
  shipmentService.runPreProcessing(CrudAction.EXPORT, {
    queryParams
  })
  const endPoint = queryParams
    ? `${basePath}/exportByCompanyReport?${queryParams}`
    : `${basePath}/exportByCompanyReport`
  try {
    let result = await api.get(endPoint, {
      responseType: 'blob'
    })
    const filename = shipmentService.getResponseFilenameWithout(result)

    return { data: result.data, filename }
  } catch (e) {
    handleError(e)
    throw e
  }
}

shipmentService.sendRejectedShipmentIds = async (ids) => {
  try {
    const response = await api.post(`${basePath}/rejectSelectedShipments`, ids)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
