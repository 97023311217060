export default {
  application: {
    name: 'Supply Brain - en',
    dateTimeFormat: 'MM/DD/YYYY HH:ss',
    smartBio: 'Smart Bio',
    selectModule: 'Select Module',
    modules:{
      plant:{
        title: 'Plant'
      },
      plantDiv:{
        title: 'Plant-Div'
      },
      plantGsel:{
        title: 'Plant-Gsel'
      },
      logistic:{
        title: 'Logistic'
      },
      forest:{
        title: 'Forest'
      }
    },
    user: {
      language: 'Language',
      userProperties: 'User Properties',
      realName: 'Real Name',
      userEmail: 'Email',
      username: 'Username',
      userProfile: 'Profile',
      logout: 'Logout'
    },
    pages: {
      home: {
        title: 'Home'
      }
    }
  }
}
