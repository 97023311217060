<template>
  <div v-if="metadata != null">
    <gerdau-crud-meta-view
      :service="service"
      :metadata="metadata"
      gridMode="clientSide"
      translatePrefix="application.pages"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
    />
    <div class="import-data">
      <ImportData
        :service="service"
        :templateUrl="templateName"
        :templateName="name"
        @update-table="refreshTable"
      />
    </div>
  </div>
</template>

<script>
import { CrudAction, notify } from '@brain/core'
import { vehicleOfferService } from '../services'
import { GerdauCrudMetaView } from '../components/crud/index'
import { handleError } from '../helpers'
import { ImportData } from '../components/importData/index'

export default {
  name: 'VehicleOffer',
  components: {
    GerdauCrudMetaView,
    ImportData
  },
  data: () => ({
    service: {
      ...vehicleOfferService
    },
    metadata: null,
    reloadData: false,
    name: 'vehicleOffer',
    nameTemplate: ''
  }),
  async created() {
    this.templateName = `./${this.name}.xlsx`
    this.service.getAll = async () => {
      try {
        return await vehicleOfferService.getVehicleOfferByUserCompany()
      } catch (e) {
        handleError(e)
      }
    }
    this.service.export = async () => {
      try {
        return await vehicleOfferService.getExportVehicleByUserCompany()
      } catch (e) {
        handleError(e)
      }
    }
    this.getMeta().then((meta) => {
      this.metadata = {
        ...meta,
        actions: [
          {
            title: this.$t('core.misc.delete'),
            className: 'fas fa-trash',
            enable: this.canDelete,
            callback: ({ node }) => {
              this.delete(node.data)
            }
          }
        ]
      }
    })
  },
  methods: {
    async refreshTable() {
      this.reloadData = true
    },
    async delete(offer) {
      if (offer.id) {
        await this.$brain.confirm(
          {
            title: this.$t('core.crud.attention'),
            subtitle: this.$tc('core.crud.alertRemoveMessage')
          },
          async () => {
            try {
              let response = null
              response = await vehicleOfferService.delete(offer.id)
              if (response?.errorItems) {
                response.errorItems.forEach((errorItem) => {
                  errorItem.fieldErrors.forEach((errorField) => {
                    this.$refs.formContainer.form.errors[errorField.fieldName] =
                      this.$t(errorField.keyMessage)
                  })
                })
                notify.error({
                  title: this.$t('core.crud.alertItemRemoveError')
                })
              } else {
                notify.success({
                  title: this.$t('core.crud.alertItemRemovedSuccess')
                })
              }
            } catch (ex) {
              console.error('Failed to remove item', ex)
              notify.error({
                title: this.$tc('core.crud.alertItemRemoveError')
              })
            }
            this.reloadData = true
          }
        )
      }
    },
    async getMeta() {
      const meta = await vehicleOfferService.getMetaWithValidation()
      meta.fields.forEach((field) => {
      if (field.id === 'vehiclesType') {
        const indexToRemoveMarket = field.options.indexOf("market")
      if (indexToRemoveMarket !== -1) {
        field.options.splice(indexToRemoveMarket, 1)
      }
      const indexToRemoveTOCO = field.options.indexOf("toco")
      if (indexToRemoveTOCO !== -1) {
        field.options.splice(indexToRemoveTOCO, 1)
      }
      }
    })

      meta.permissions = [
        CrudAction.UPDATE,
        CrudAction.CREATE,
        CrudAction.IMPORT,
        CrudAction.EXPORT
      ]
      return meta
    }
  }
}
</script>
<style lang="scss" scoped></style>
