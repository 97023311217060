import { notify } from '@brain/ui'
import BrainCore from '../boot/brainCore.js'
import _ from 'lodash'

export const processErrors = (errors) => {
  if (_.isObject(errors)) {
    errors = Object.values(errors).flat()
  }
  return (errors ?? []).map((error) => {
    if (_.isString(error)) {
      return BrainCore.i18n.t(error)
    }
    return error
  })
}

export const handleError = (error) => {
  if (error.response && error.response.data) {
    if (error.response.status == 400 && error.response.data.errors) {
      Object.keys(error.response.data.errors).forEach((errorKey) => {
        notify.error({
          title: errorKey,
          description: error.response.data.errors[errorKey]
            .map((errorMsg) => errorMsg)
            .join('\n')
        })
      })
    } else if (error.response.data.errors) {
      processErrors(error.response.data.errors).forEach((error) => {
        if (_.isString(error)) {
          notify.error({
            title: error
          })
        } else {
          Object.keys(error.errors).forEach(
            (field) =>
              (error.errors[field] = error.errors[field].map((_error) =>
                notify.error({
                  title: _error
                })
              ))
          )
        }
      })
    } else if (
      error.response.data.message != null &&
      _.isString(error.response.data.message)
    ) {
      notify.error({
        title: error.response.data.message
      })
    } else {
      notify.error({
        title: error,
        description: error.response.data
      })
    }
  } else {
    notify.error({ title: error, description: error.message })
  }
}
