export default {
  application: {
    name: 'Supply Brain - ptBR',
    companyModule: 'Módulo Transportador',
    dateTimeFormat: 'DD/MM/YYYY HH:ss',
    smartBio: 'Smart Bio',
    selectModule: 'Selecione o Módulo',
    smartBioLogistic: 'Smart Bio - Transportadores',
    menu: {
      inputData: {
        title: 'Entrada de Dados',
        success: 'Importação realizada com sucesso!',
        process: 'Linhas processadas',
        update: 'Linhas adicionadas/atualizadas',
        erro: 'Linhas com erro',
        erroDetected: ' Foram detectados os seguintes erros',
        btnCancel: ' Cancelar',
        btnFinish: ' Finalizar',
        btnDownTemplate: 'Baixar Template',
        btnSelectFile: 'Selecionar Arquivo'
      }
    },
    enums: {
      vehicleTypeEnum: {
        moveableFloor: 'Piso Móvel',
        boxTruck: 'Baú',
        truck: 'Truck',
        market: 'Mercado',
        toco: 'Toco'
      }
    },
    modules: {
      plant: {
        title: 'Usina'
      },
      plantDiv: {
        title: 'Usina-Div'
      },
      plantGsel: {
        title: 'Usina-Gsel'
      },
      logistic: {
        title: 'Logística'
      },
      forest: {
        title: 'Florestal'
      }
    },
    user: {
      language: 'Linguagem',
      userProperties: 'Propriedades do Usuário',
      realName: 'Nome',
      userEmail: 'Email',
      username: 'Nome de Usuário',
      userProfile: 'Perfil',
      logout: 'Sair'
    },
    pages: {
      general: {
        title: 'Dados Gerais'
      },
      report: {
        title: 'Relatórios'
      },
      home: {
        title: 'Início'
      },
      vehicleOffer: {
        title: 'Oferta de Veículos',
        company: 'Transportadora',
        cnpjCompany: 'Cnpj Transportador',
        numberVehicles: 'Quantidade de Veículos',
        vehiclesType: 'Tipo de Veículo',
        date: 'Dia'
      },
      vehicle: {
        title: 'Veículo',
        company: 'Transportador',
        cnpjCompany: 'Cnpj Transportador',
        vehicleType: 'Tipo de Veículo',
        licensePlate: 'Placa do Veículo',
        capacity: 'Capacidade (M³)',
        active: 'Ativo',
        enums: {
          vehicleTypeEnum: {
            moveableFloor: 'Piso Móvel',
            boxTruck: 'Baú',
            truck: 'Truck',
            market: 'Mercado'
          }
        }
      },
      driver: {
        title: 'Motorista',
        name: 'Nome',
        cpf: 'CPF',
        driverLicense: 'CNH',
        company: 'Transportador',
        cnpjCompany: 'Cnpj Transportador',
        phoneNumber: 'Telefone',
        active: 'Ativo'
      },
      shipment: {
        acceptSelectedShipments: 'Aceitar Viagens Selecionadas',
        myShipments: 'Minhas Viagens',
        titleShipmentsFollowUp: 'Acompanhamento de Viagens',
        registerAbsence: 'Lançar Falta para Selecionados',
        title: 'Viagem',
        id: 'Id Viagem',
        company: 'Transportador',
        status: 'Status',
        vehicle: 'Veículo',
        truckTractor: 'Cavalo',
        route: 'Rota',
        forest: 'Planta (Origem)',
        plant: 'Usina (Destino)',
        labelSelect: 'Filtro Usina (Destino)',
        queueTime: 'Tempo de Fila',
        createShipment: 'Criar Viagem',
        estimatedArrivalForestTime: 'Prev. chegada Floresta',
        estimatedDepartureForestTime: 'Prev. Saída Floresta',
        estimatedArrivalPlantTime: 'Prev. Transit Time',
        estimatedDeparturePlantTime: 'Agend. Descarga',
        acceptShipment: 'Deseja Aceitar a viagem?',
        rejecttShipment: 'Deseja Rejeitar a viagem?',
        throwAbsence: 'Deseja lançar falta para a viagem selecionada?',
        notifyWarning:
          'Campos destacados são obrigatórios. Preencha-os antes de continuar.',
        notifyTitle: 'ATENÇÃO!',
        sendAbsenceTracking: 'Lançar falta',
        refuseTravel: 'Recusar Viagem',
        acceptTravel: 'Aceitar Viagem',
        measuredVolume: 'Volume Medido'
      },
      truckTractor: {
        title: 'Cavalo',
        company: 'Transportadora',
        cnpjCompany: 'Cnpj Transportador',
        licensePlate: 'Placa',
        capacity: 'Capacidade'
      },
      shipmentTable: {
        title: 'Viagem',
        id: 'Id Viagem',
        company: 'Transportador',
        status: 'Status',
        vehicle: 'Veículo',
        route: 'Rota',
        forest: 'Planta (Origem)',
        plant: 'Usina (Destino)',
        labelSelect: 'Filtro Usina (Destino)',
        queueTime: 'Tempo de Fila',
        createShipment: 'Criar Viagem',
        estimatedArrivalForestTime: 'Prev. chegada Floresta',
        estimatedDepartureForestTime: 'Prev. Saída Floresta',
        estimatedArrivalPlantTime: 'Prev. Transit Time',
        estimatedDeparturePlantTime: 'Agend. Descarga',
        enums: {
          status: {
            editing: 'Viagem em  Edição',
            published: 'Pendente Aceite',
            confirmed: 'Viagem Aceita',
            rejected: 'Viagem Rejeitada',
            inProgress: 'Viagem em Andamento',
            delayed: 'Viagem Atrasada',
            completed: 'Viagem Concluída'
          }
        }
      },
      shipmentsByCompany: {
        title: 'Viagem',
        id: 'Id Viagem',
        driver: 'Motorista',
        company: 'Transportador',
        status: 'Status',
        vehicle: 'Veículo',
        truckTractor: 'Cavalo',
        route: 'Rota',
        forest: 'Planta (Origem)',
        plant: 'Usina (Destino)',
        labelSelect: 'Filtro Usina (Destino)',
        queueTime: 'Tempo de Fila',
        throwShipmentAbsenceDescription: 'Lançar falta',
        throwShipmentAbsenceDescriptionHint: 'Lançar falta',
        estimatedArrivalForestTime: 'Prev. chegada Floresta',
        estimatedDepartureForestTime: 'Prev. Saída Floresta',
        estimatedArrivalPlantTime: 'Prev. Transit Time',
        estimatedDeparturePlantTime: 'Agend. Descarga',
        absenceTrackingLabel: 'Falta',
        success: 'Sucesso!',
        measuredVolume: 'Volume Medido',
        absenceTrackingSuccess: 'Falta lançada com sucesso.',
        enums: {
          status: {
            editing: 'Viagem em  Edição',
            published: 'Pendente Aceite',
            confirmed: 'Viagem Confirmada',
            rejected: 'Viagem Rejeitada',
            miss: 'Falta',
            inTraffic: 'Viagem em Trânsito',
            waitingToLoad: 'Aguard. Carregamento',
            waitingToUnload: 'Aguard. Descarga',
            unloading: 'Descarregando',
            completed: 'Viagem Finalizada',
            inProgress: 'Em Progresso'
          }
        }
      },
      shipmentsByCompanyReport: {
        title: 'Minhas Viagens',
        id: 'Id Viagem',
        driver: 'Motorista',
        company: 'Transportador',
        status: 'Status',
        vehicle: 'Veículo',
        truckTractor: 'Cavalo',
        route: 'Rota',
        forest: 'Planta (Origem)',
        plant: 'Usina (Destino)',
        labelSelect: 'Filtro Usina (Destino)',
        queueTime: 'Tempo de Fila',
        throwShipmentAbsenceDescription: 'Lançar falta',
        throwShipmentAbsenceDescriptionHint: 'Lançar falta',
        estimatedArrivalForestTime: 'Prev. chegada Floresta',
        estimatedDepartureForestTime: 'Prev. Saída Floresta',
        estimatedArrivalPlantTime: 'Prev. Transit Time',
        estimatedDeparturePlantTime: 'Agend. Descarga',
        absenceTrackingLabel: 'Falta',
        success: 'Sucesso!',
        measuredVolume: 'Volume Medido',
        absenceTrackingSuccess: 'Falta lançada com sucesso.',
        forestCheckin: 'Planta Check-in',
        forestCheckout: 'Planta Check-out',
        plantYardCheckin: 'Pátio Check-in',
        plantCheckin: 'Usina Check-in',
        plantCheckout: 'Usina Check-out',
        enums: {
          status: {
            editing: 'Viagem em  Edição',
            published: 'Pendente Aceite',
            confirmed: 'Viagem Confirmada',
            rejected: 'Viagem Rejeitada',
            miss: 'Falta',
            inTraffic: 'Viagem em Trânsito',
            waitingToLoad: 'Aguard. Carregamento',
            waitingToUnload: 'Aguard. Descarga',
            unloading: 'Descarregando',
            completed: 'Viagem Finalizada',
            inProgress: 'Em Progresso'
          }
        }
      }
    }
  }
}
