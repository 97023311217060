<template>
  <div class="bc-crud-filter-set">
    <div class="bc-crud-filter-set__header">
      <bc-text-field
        embedded
        hide-details="auto"
        :placeholder="$t('crud.filter.searchPlaceholder')"
        v-model="filterText"
      />
      <bc-checkbox
        dense
        hide-details="auto"
        :label="$t('crud.filter.selectAll')"
        :input-value="selectAll === true"
        :indeterminate="selectAll === null"
        @change="onToggleSelectAll"
      />
    </div>
    <bc-divider horizontal />
    <div
      class="bc-crud-filter-set__item-list"
      :style="{ height: listHeight }"
      :aria-rowcount="items.length"
    >
      <template v-if="loading">
        <div class="bc-crud-filter-set__loading-container">
          <bc-loading :blockUi="false" size="md" class="white--text" />
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in filteredItems"
          :key="index"
          :aria-rowindex="index"
          class="bc-crud-filter-set__item"
        >
          <bc-checkbox
            dense
            hide-details="auto"
            :label="item.formattedValue"
            :input-value="_isSelected(item)"
            @change="onSelectItem(item, $event)"
          />
        </div>
      </template>
    </div>
    <div class="bc-crud-filter-set__footer">
      <bc-btn color="link-blue" @click.prevent="onResetFilter">
        {{ $t('crud.filter.resetFilter') }}
      </bc-btn>
      <bc-btn
        color="primary"
        :disabled="shouldDisableFilter"
        @click.prevent="onApplyFilter"
      >
        {{ $t('crud.filter.applyFilter') }}
      </bc-btn>
    </div>
  </div>
</template>

<script>
import { helpers } from '@brain/grid'
import { BcBtn, BcTextField, BcCheckbox, BcDivider, BcLoading } from '@brain/ui'
import Vue from 'vue'
import { eventHub } from '../../boot/eventhub'

export default Vue.extend({
  name: 'GerdauCrudFilterSet',
  components: {
    BcBtn,
    BcTextField,
    BcCheckbox,
    BcDivider,
    BcLoading
  },
  data() {
    return {
      field: '',
      filterText: '',
      items: [],
      selectedItems: [],
      model: {},
      checkFilterModel: null,
      loading: false
    }
  },
  mounted() {
    this.field = this.params.colDef.field
    this.model = {
      ...this.params.api.getFilterModel(),
      [this.field]: undefined
    }
    this._loadItems()
    this.checkFilterModel = this._throttle(this._checkFilterModel, 1000)
    this.reloadItemsFunc = () => this._loadItems()
    eventHub.$on('reload-crud-filters', this.reloadItemsFunc)
  },
  beforeDestroy() {
    eventHub.$off('reload-crud-filters', this.reloadItemsFunc)
  },
  computed: {
    valueFormatter() {
      const formatter =
        this.params.valueFormatter || this._defaultValueFormatter
      return (value) => formatter({ value })
    },
    filteredItems() {
      const filter = this.filterText.toLocaleLowerCase()
      return this.items.filter((item) => {
        const formattedValue = item.formattedValue ?? ''
        return formattedValue.toLocaleLowerCase().includes(filter)
      })
    },
    selectAll() {
      if (this.selectedItems.length === this.items.length) return true
      else if (this.selectedItems.length === 0) return false
      else return null
    },
    listHeight() {
      const gridHeight = this.params.gridHeight ?? 290
      const listHeight = (gridHeight * 25) / 100
      return `${listHeight}px`
    },
    shouldDisableFilter() {
      return this.selectedItems.length === 0
    }
  },
  methods: {
    onApplyFilter() {
      this.params.filterChangedCallback()
    },
    onResetFilter() {
      this.selectedItems = [...this.items]
      this.params.filterChangedCallback()
    },
    onSelectItem(item) {
      const isAlreadySelected = this._isSelected(item)
      if (isAlreadySelected) {
        this.selectedItems = this.selectedItems.filter((i) => i !== item)
      } else {
        this.selectedItems.push(item)
      }
    },
    onToggleSelectAll() {
      if (this.selectAll) {
        this.selectedItems = []
      } else {
        this.selectedItems = [...this.items]
      }
    },

    // This filter should not be used in client side filtering.
    doesFilterPass() {
      return true
    },
    isFilterActive() {
      return !this.selectAll
    },
    getModel() {
      let model
      if (this.selectAll) {
        model = null
      } else {
        model = {
          values: this.selectedItems.map((item) =>
            this._formatFilterValue(item.rawValue)
          ),
          filterType: 'set'
        }
      }

      return model
    },
    setModel(model) {
      if (!model) {
        this.selectedItems = [...this.items]
      } else {
        this.selectedItems = this.items.filter((item) =>
          model.values.includes(this._formatFilterValue(item.rawValue))
        )
      }
    },
    onNewRowsLoaded() {
      this.checkFilterModel()
    },

    async _values() {
      const queryParamsObject = helpers.buildQueryObject(
        {
          filterModel: this.model
        },
        { objectFields: this.params.objectFields }
      )
      const filterQuery = helpers.buildQueryParams(queryParamsObject)
      const options = this.params.values
      let values = []

      if (typeof options === 'function') {
        this.loading = true
        values = await options({ filterQuery })
        this.loading = false
      } else if (Array.isArray(options)) {
        values = options
      }

      values = values.map((item) => ({
        rawValue: item,
        formattedValue: this.valueFormatter(item)
      }))

      return values
    },
    async _loadItems() {
      const selectAll = this.selectAll
      this.items = await this._values()

      if (selectAll) {
        this.selectedItems = [...this.items]
      } else {
        const modelValues = this.selectedItems.map((item) =>
          this._formatFilterValue(item.rawValue)
        )
        this.selectedItems = this.items.filter((item) =>
          modelValues.includes(this._formatFilterValue(item.rawValue))
        )
      }
    },
    _isSelected(item) {
      return this.selectedItems.includes(item)
    },
    _defaultValueFormatter(params) {
      if (params.value == null) return ''
      return (
        typeof params.value === 'object' ? params.value.text : params.value
      )?.toString()
    },
    _formatFilterValue(item) {
      if (item == null) return 'null'
      return typeof item === 'object' ? item.value : item
    },
    _checkFilterModel() {
      const oldModel = this.model
      const newModel = {
        ...this.params.api.getFilterModel(),
        [this.field]: undefined
      }
      this.model = newModel

      // If the models do not match, reloade the items
      if (JSON.stringify(oldModel) !== JSON.stringify(newModel)) {
        this._loadItems()
      }
    },
    _throttle(fn, wait) {
      let inThrottle, lastFn, lastTime
      return function () {
        const context = this,
          args = arguments
        if (!inThrottle) {
          fn.apply(context, args)
          lastTime = Date.now()
          inThrottle = true
        } else {
          clearTimeout(lastFn)
          lastFn = setTimeout(function () {
            if (Date.now() - lastTime >= wait) {
              fn.apply(context, args)
              lastTime = Date.now()
            }
          }, Math.max(wait - (Date.now() - lastTime), 0))
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.bc-crud-filter-set {
  &__header {
    margin-bottom: 10px;
  }

  &__item-list {
    overflow-y: auto;
    margin-bottom: 10px;
    min-height: 88px;
    max-height: 200px;
  }

  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
