import BrainCore from '@brain/core'
import Vue from 'vue'
import appLocales from '../locales/'
import routes from '../router/routes'
import authService from './authService'
import { useTransactions } from '../stores/transaction'
import { eventHub } from './eventhub'
Vue.use(BrainCore)

const brainCore = new BrainCore({
  i18n: {
    messages: appLocales,
    locale: 'pt-BR',
    fallbackLocale: 'pt-BR'
  },
  router: {
    routes,
    disableBreadCrumbGeneration: false,
    disablePrefixChildRoutesAutoComplete: false
  }
})

brainCore.router.beforeEach(async (to, from, next) => {
  const authRequired = !to.public
  const isLoggedIn = authService.isLoggedIn()
  if (authRequired && !isLoggedIn) {
    authService.doLogin()
  } else {
    const { canRead } = useTransactions()
    const canAcessPage = !to.meta.transaction || canRead(to.meta.transaction)
    const itIsLoggedInLoginPage =
      (to.name === 'Login' || to.name === 'LoginForm') && isLoggedIn
    if (!canAcessPage || itIsLoggedInLoginPage) {
      next({ path: '/' })
      return
    }
    const parts = to.name.split('-')
    const importPattern = /import$/
    const createPattern = /create$/

    const endPath = parts[parts.length - 1]

    if (createPattern.test(endPath)) {
      eventHub.$emit('handleCreate')
      next()
    } else {
      eventHub.$emit('handleCreate')
    }

    if (importPattern.test(endPath)) {
      eventHub.$emit('callIsImport')
      return
    } else {
      next()
    }
  }
})
export default brainCore
