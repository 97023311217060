import { createCrudService } from '../boot/gerdauCrudService'
import api from '../boot/api'

const basePath = '/logisticaApi/absenceTracking'
const shipmentAbsenceService = createCrudService(basePath, api)

export default shipmentAbsenceService

shipmentAbsenceService.saveShipmentAbsence = async (shipmentId, message) => {
  try {
    const response = await api.post(`${basePath}/saveShipmentAbsence`, {
      id: shipmentId,
      description: message
    })
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}