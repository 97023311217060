import { createCrudService } from '../boot/gerdauCrudService'
import api from '../boot/api'
import { responseToOptionsInContext } from '../boot/baseApi'
import { handleError } from '../helpers/errorHandler'
const vehicleService = createCrudService('/logisticaApi/vehicle', api)

export default vehicleService

vehicleService.getVehicleByUserCompany = async () => {
  let result = await api.get(`/logisticaApi/vehicle/getVehicleByUserCompany`)
  const data = Array.isArray(result.data.data) ? result.data.data : result.data
  return data
}

vehicleService.getExportVehicleByUserCompany = async () => {
  try {
    let result = await api.get(`/logisticaApi/vehicle/exportByCompany`, {
      responseType: 'blob'
    })
    const filename = vehicleService.getResponseFilenameWithout(result)

    return { data: result.data, filename }
  } catch (e) {
    handleError(e)
    throw e
  }
}
vehicleService.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.company.options = ctx.companyOptions
}
