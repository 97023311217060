import axios from 'axios'
import brainCore from './brainCore'
import authService from './authService'

const api = axios.create({})

api.interceptors.request.use(async (config) => {
  if (authService.isLoggedIn()) {
    config.headers['Authorization'] = `Bearer ${authService.getToken()}`
  }
  config.headers['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      brainCore.router.instance().push('/login')
    }
    return Promise.reject(error)
  }
)

export default api
