import api from './api'
import _ from 'lodash'

export const responseToOptions = (options, valueParseFunc) => {
  if (valueParseFunc == null) {
    valueParseFunc = (id) => parseInt(id)
  }
  if (_.isArray(options)) {
    return options
  }
  const optionsObject = Object.keys(options ?? {})?.map((id) => {
    return {
      label: options[id],
      text: options[id],
      value: valueParseFunc(id, options),
      toString() {
        return this.text
      }
    }
  })
  return _.sortBy(optionsObject, (x) => x.label)
}

const optionsSuffix = 'Options'

export const responseToOptionsInContext = (ctx) => {
  const keys = Object.keys(ctx ?? {}).filter((x) => x.endsWith(optionsSuffix))
  for (const key of keys) {
    ctx[key] = responseToOptions(ctx[key])
  }
}

export const getFieldOptions = async (optionsSource) => {
  try {
    return responseToOptions((await api.get(`${optionsSource}`))?.data)
  } catch (err) {
    console.error('Failed to get field options', err)
    return null
  }
}

export const getFieldOptionsFunc = (optionsSource) => {
  return async () => await getFieldOptions(optionsSource)
}
