<template>
  <div>
    <bc-modal
      v-model="showModalLog"
      title="Resultado da importação"
      width="800"
      height="max-content"
    >
      <div class="title-log-container">
        <bc-icon color="#86e11e" size="30" style="margin-right: 0.5rem">
          fa-check-square</bc-icon
        >
        {{ $t('application.menu.inputData.success') }}
      </div>

      <div class="container-card-log">
        <div class="log-process">
          <p>{{ $t('application.menu.inputData.process') }}</p>
          {{ totalRowsSent }}
        </div>

        <div class="log-add">
          <p>{{ $t('application.menu.inputData.update') }}</p>
          {{ totalSuccess }}
        </div>
        <div class="log-error">
          <p>{{ $t('application.menu.inputData.erro') }}</p>
          {{ totalWarnings }}
        </div>
      </div>

      <p>{{ $t('application.menu.inputData.erroDetected') }}</p>
      <div class="messages-log" style="white-space: pre-wrap">
        <ul>
          <li v-for="message in warningMessages" :key="message">
            {{ message }}
          </li>
        </ul>
      </div>
    </bc-modal>
    <bc-modal
      v-model="showModal"
      title="Importar dados"
      width="800"
      height="max-content"
    >
      <div v-if="!isSubmitting">
        <div
          class="import-container"
          :class="{ 'drag-over': isDragOver }"
          @dragover.prevent
          @drop="handleFileDrop"
          @dragenter="highlightDropArea"
          @dragleave="unhighlightDropArea"
        >
          <div v-if="!selectedFileName">
            <bc-icon color="primary" class="icon" size="60">fa-copy</bc-icon>
          </div>
          <div v-if="selectedFileName">
            <bc-icon color="primary" class="icon" size="60"
              >fas fa-arrow-circle-up</bc-icon
            >
          </div>

          {{ selectedFileName || 'Selecione ou arraste o arquivo' }}
          <bc-btn color="primary" @click="openFileInput">
            {{ $t('application.menu.inputData.btnSelectFile') }}
          </bc-btn>

          <input
            id="fileInput"
            type="file"
            ref="fileInput"
            accept=".xlsx"
            :value="null"
            @change="handleFileSelected"
          />
        </div>
      </div>

      <div class="is-loading" v-else>
        <bc-loading :blockUi="false" size="md" class="white--text" />
      </div>

      <template v-slot:footer>
        <bc-btn color="link-blue" @click="isClickCancel">{{
          $t('application.menu.inputData.btnCancel')
        }}</bc-btn>
        <bc-btn color="primary" @click="submitForm" :disabled="isSubmitting">
          {{ $t('application.menu.inputData.btnFinish') }}
        </bc-btn>
      </template>
    </bc-modal>
  </div>
</template>

<script>
import { BcModal, BcBtn, BcIcon } from '@brain/ui'
import * as XLSX from 'xlsx'
import BrainCore from '../../boot/brainCore.js'
import { defineComponent } from '@vue/composition-api'
import { eventHub } from '../../boot/eventhub'
import moment from 'moment'

export default defineComponent({
  name: 'ImportData',
  props: {
    service: {
      type: Object,
      default: null
    },
    metadata: {
      type: Object,
      default: null
    },
    templateUrl: String,
    templateName: String,
    isPivot: { default: false, type: Boolean }
  },
  components: {
    BcModal,
    BcBtn,
    BcIcon
  },
  data: () => ({
    showModal: false,
    selectedFile: null,
    selectedFileName: null,
    isDragOver: false,
    isSubmitting: false,
    showModalLog: false,
    totalRowsSent: 0,
    totalWarnings: 0,
    totalSuccess: 0,
    warningMessages: [],
    updateTable: false
  }),
  mounted() {
    // Ouvindo o evento emitido pelo EventBus
    eventHub.$on('callIsImport', () => {
      this.showModal = true
    })
  },
  created() {
    const columnsTranslate = BrainCore.i18n.t(
      `application.pages.${this.templateName}`
    )
    let columns = Object.fromEntries(
      Object.entries(columnsTranslate)
        .filter(([key, value]) => key !== 'title' && typeof value !== 'object')
        .map(([key, value]) => [value, this.capitalizeFirstLetter(key)])
    )
    if (this.isPivot) {
      const groupField = this.metadata.fields.filter(
        ({ groupedKey }) => groupedKey
      )
      let columnsPivot = Object.entries(columnsTranslate).filter(
        ([key, value]) => key !== 'title' && !this.validateDateMoment(key)
      )
      columns = []
      for (const item in columnsPivot) {
        if (groupField.some((objeto) => objeto.key === columnsPivot[item][0])) {
          const column = columnsPivot[item]
          let invertedArray = [column[1], column[0]]
          columns.push(invertedArray)
        }
        const keySplit = columnsPivot[item][0].split('-')
        const column = columnsPivot[item]
        let invertedArray = [`${column[1]}-${keySplit[1]}`, column[0]]
        if (keySplit.length > 1 && this.validateDateMoment(keySplit[1])) {
          columns.push(invertedArray)
        }
      }
      let objs = {}
      for (let key in columns) {
        // eslint-disable-next-line no-prototype-builtins
        if (columns.hasOwnProperty(key)) {
          objs[columns[key][0]] = columns[key][1]
        }
      }
      columns = this.ordemFieldPivot(this.metadata.fields, objs)
    }
    this.columnMapping = columns
  },
  methods: {
    capitalizeFirstLetter(input) {
      if (typeof input !== 'string' || input.length === 0) {
        return input
      }

      return input.charAt(0).toUpperCase() + input.slice(1)
    },
    validateDateMoment(stringData) {
      if (moment(stringData, 'DD/MM/YYYY', true).isValid()) {
        return true
      } else if (moment(stringData, 'MM/YYYY', true).isValid()) {
        return true
      } else {
        return false
      }
    },
    ordemFieldPivot(fieldsMeta, fieldsTable) {
      let objs = {}
      for (const fieldMeta of fieldsMeta) {
        for (const key in fieldsTable) {
          if (fieldsTable[key] === fieldMeta.key) {
            objs[key] = fieldsTable[key]
            break // Para a busca assim que encontrar um match
          }
        }
      }
      return objs
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    isClickCancel() {
      ;(this.showModal = false),
        (this.selectedFile = null),
        (this.selectedFileName = null),
        (this.tempSelectedFile = null)
    },
    handleFileSelected(event) {
      const file = event.target.files[0]
      if (file !== null) {
        this.tempSelectedFile = file
        this.selectedFileName = file.name
      }
    },
    handleFileDrop(event) {
      event.preventDefault()
      this.tempSelectedFile = event.dataTransfer.files[0]
      this.selectedFileName = this.tempSelectedFile.name
      this.isDragOver = false
    },

    highlightDropArea() {
      this.isDragOver = true
    },

    unhighlightDropArea() {
      this.isDragOver = false
    },
    clearFileInput() {
      this.tempSelectedFile = null
      this.selectedFileName = null
      this.isDragOver = false
    },
    async submitForm() {
      if (this.tempSelectedFile) {
        try {
          this.totalSuccess = 0
          this.totalRowsSent = 0
          this.totalWarnings = 0
          this.warningMessages = []
          this.isSubmitting = true
          const json = await this.convertExcelToJson(this.tempSelectedFile)
          this.totalRowsSent = json.length
          await this.sendDataToEndpoint(json)
          this.showModal = false
          this.clearFileInput()
          this.updateTable = true
          this.$emit('update-table')
        } catch (error) {
          console.error('Erro ao converter arquivo Excel:', error)
        } finally {
          this.isSubmitting = false
          this.showModalLog = true
          this.updateTable = false
        }
      } else {
        console.log('Nenhum arquivo selecionado.')
      }
    },
    async sendDataToEndpoint(data) {
      try {
        const response = await this.service.batchUpdateExcel(data)
        this.warningMessages.length = 0
        if (response?.data.errors) {
          response?.data.errors.forEach((item) => {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('errors') && item.hasOwnProperty('rowId')) {
              const rowId = item.rowId
              const erros = item.errors

              Object.keys(erros).forEach((key) => {
                erros[key].forEach((ErrorMessage) => {
                  this.warningMessages.push(
                    `${this.$t(ErrorMessage)} --- Erro na Linha: ${rowId}`
                  )
                })
              })
            }
          })
        }

        this.totalSuccess =
          response?.data.insertedRows + response?.data.updatedRows
        this.totalRowsSent = response?.data.totalRows
        this.totalWarnings = response?.data.errorRows
      } catch (error) {
        console.error('Error sending data to the server:', error)
      }
    },
    async convertExcelToJson(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const jsonData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName],
            {
              header: Object.keys(this.columnMapping),
              defval: '',
              raw: false,
              range: 1,
              blankrows: false,
              skipHidden: true,
              dateNF: 'yyyy-mm-dd'
            }
          )

          const normalizedColumnMapping = {}
          for (const key in this.columnMapping) {
            normalizedColumnMapping[key.toLowerCase()] = this.columnMapping[key]
          }
          jsonData.forEach((row) => {
            for (const key in row) {
              if (normalizedColumnMapping[key.toLowerCase()]) {
                const originalKey = normalizedColumnMapping[key.toLowerCase()]
                if (!row[originalKey]) {
                  row[originalKey] = row[key]
                  delete row[key]
                }
              }
            }
          })
          if (this.isPivot) {
            jsonData.shift()
          }
          resolve(jsonData)
        }

        reader.onerror = (error) => {
          reject(error)
        }

        reader.readAsArrayBuffer(file)
      })
    }
  }
})
</script>

<style>
.import-container {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  border: dashed 1px #229cc5;
  color: #24bbef;
  gap: 0.6rem;
}

.icon {
  width: 100px;
  font-size: 100px;
}

.import-container input {
  display: none;
}

.drag-over {
  border: 2px dashed green;
}

.is-loading {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-log-container {
  color: #86e11e;
}

.log-process {
  width: 15rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  border: 1px solid #89cd4d;
  border-radius: 5px;
  padding-top: 1rem;
}

.log-process p {
  font-size: 1rem;
}

.log-add {
  width: 15rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  border: 1px solid #24bbef;
  border-radius: 5px;
  padding-top: 1rem;
}

.log-add p {
  font-size: 1rem;
}

.log-error {
  width: 15rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  border: 1px solid #d2354f;
  border-radius: 5px;
  padding-top: 1rem;
}

.log-error p {
  font-size: 1rem;
}

.container-card-log {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.messages-log {
  width: 100%;
  height: 200px;
  padding: 1rem;
  background-color: #191c23;
  overflow: auto;
}
</style>
