import { createCrudService } from '../boot/gerdauCrudService'
import api from '../boot/api'
import { responseToOptionsInContext } from '../boot/baseApi'
import { CrudAction } from '@brain/core'
import { handleError } from '../helpers/errorHandler'

const vehicleOfferService = createCrudService('/logisticaApi/vehicleOffer', api)
const formatDateFilter = 'YYYY-MM-DD'
vehicleOfferService.basePath = '/logisticaApi/vehicleOffer'
vehicleOfferService.formatDateFilter = formatDateFilter
export default vehicleOfferService

vehicleOfferService.getVehicleOfferByUserCompany = async () => {
  let result = await api.get(
    `/logisticaApi/vehicleOffer/getVehicleOfferByUserCompany`
  )
  const data = Array.isArray(result.data.data) ? result.data.data : result.data
  return data
}

vehicleOfferService.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.company.options = ctx.companyOptions
}
vehicleOfferService.getExportVehicleByUserCompany = async () => {
  try {
    let result = await api.get(`/logisticaApi/vehicleOffer/exportByCompany`, {
      responseType: 'blob'
    })
    const filename = vehicleOfferService.getResponseFilenameWithout(result)

    return { data: result.data, filename }
  } catch (e) {
    handleError(e)
    throw e
  }
}
vehicleOfferService.delete = async (...id) => {
  try {
    vehicleOfferService.runPreProcessing(CrudAction.DELETE, { ids: [id] })
    const response = await api.delete(`${vehicleOfferService.basePath}/${id}`)
    vehicleOfferService.runPostProcessing(CrudAction.DELETE, {
      ids: [id],
      response
    })
  } catch (e) {
    handleError(e)
    return { ...e, errorItems: [], hasErrors: true }
  }
}
