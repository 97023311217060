<template>
  <div>
    <div v-if="metadata != null">
      <gerdau-crud-meta-view
        :service="service"
        :metadata="metadata"
        translatePrefix="application.pages"
      />
    </div>
    <div class="import-data">
      <ImportData
        :service="service"
        :templateUrl="templateName"
        :templateName="name"
        @update-table="refreshTable"
      />
    </div>
  </div>
</template>

<script>
import { GerdauCrudMetaView } from '../components/crud/index'
import { CrudAction } from '@brain/core'
import { truckTractorService } from '../services'
import { ImportData } from '../components/importData/index'
import { helpers } from '../helpers'
import { handleError } from '../helpers'
import api from '../boot/api'

export default {
  name: 'TruckTractor',
  components: {
    GerdauCrudMetaView,
    ImportData
  },
  data: () => ({
    service: {
      ...truckTractorService
    },
    metadata: null,
    name: 'truckTractor',
    templateName: ''
  }),
  methods: {
    async refreshTable() {
      let bcGrid = helpers.findComponent(this, 'BcGrid')
      const [plantData] = await Promise.all([this.service.getAll()])
      bcGrid.aggridApi.setRowData(plantData)
    },
    async getMeta() {
      const meta = await truckTractorService.getMetaWithValidation()
      meta.permissions = [
        CrudAction.UPDATE,
        CrudAction.CREATE,
        CrudAction.DELETE,
        CrudAction.IMPORT,
        CrudAction.EXPORT
      ]
      return meta
    }
  },
  created() {
    this.templateName = `./${this.name}.xlsx`
    this.service.getAll = async () => {
      try {
        return await truckTractorService.getTruckTractorByUserCompany()
      } catch (e) {
        handleError(e)
      }
    }
    this.service.export = async () => {
      try {
        return await truckTractorService.getExportTruckTractorByUserCompany()
      } catch (e) {
        handleError(e)
      }
    }
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
<style lang="scss" scoped></style>
