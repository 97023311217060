import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue'
import authService from './boot/authService'
import pinia from './boot/pinia'
import brainCore from './boot/brainCore'
import { useTransactions } from './stores/transaction'

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)

const boostrap = async () => {
  try {
    // Await fot the auth server to initialize before mounting the application
    await authService.init()
    const transactions = useTransactions(pinia)
    transactions.fetchTransactions()
    const app = new Vue({
      pinia,
      ...brainCore,
      render: (h) => h(App)
    })

    app.$mount('#app')
  } catch (ex) {
    console.error('Failed to bootstrap the application', ex)
  }
}

boostrap()
