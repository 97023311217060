<template>
  <div v-if="metadata != null">
    <div style="display: flex; margin-top: 1rem">
      <div class="button-header-container1">
        <bc-btn color="primary" @click="acceptSelectedShipments">
          {{ $t('application.pages.shipment.acceptSelectedShipments') }}
        </bc-btn>
      </div>
    </div>
    <bc-crud-meta-view
      :service="customService"
      :metadata="metadata"
      :showGridActions="true"
      :customGridOptions="customGridOptions"
      gridMode="clientSide"
      translate-prefix="application.pages"
    />
    <bc-confirm-dialog
      v-model="showConfirm"
      type="warning"
      icon="fa-exclamation-circle"
      :title="confirmMessages.title"
      :subtitle="confirmMessages.subtitle"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      @confirm="handleUpdateTable()"
    ></bc-confirm-dialog>
    <throw-shipment-absence
      v-if="showAbsenceDialog"
      :shipment="shipmentAbsence"
      @closeAbsenceDialog="closeAbsenceDialog"
    >
    </throw-shipment-absence>
  </div>
</template>
<script>
import { BcCrudMetaView, CrudAction } from '@brain/core'
import { notify } from '@brain/ui'
import helpers from '../helpers/findComponent'
import { handleError } from '../helpers/errorHandler'
import { driverService, shipmentService } from '../services'
import ShipmentStatusEnum from '../utils/shipmentEnum'
import ShipmentActionEnum from '../utils/shipmentActionEnum'
import { generateMessages } from '../utils/generateConfirmDialogMessages'
import ThrowShipmentAbsence from '../components/ThrowShipmentAbsence.vue'

export default {
  name: 'Shipment',
  components: {
    BcCrudMetaView,
    ThrowShipmentAbsence
  },
  data: () => ({
    showConfirm: false,
    showAbsenceDialog: false,
    nodeId: null,
    shipmentAbsence: null,
    selectedAction: null,
    metadata: null,
    isAlert: false,
    confirmMessages: {
      title: '',
      subtitle: ''
    },
    customGridOptions: {
      hideActions: [CrudAction.UPDATE],
      pagination: false,
      showGridCheckbox: true
    },
    statusDefinitions: [
      { status: 'shipments', color: '#708090' },
      { status: 'published', color: '#8CB965' },
      { status: 'confirmed', color: '#1B67B3' },
      { status: 'inTraffic', color: '#E19F3B' },
      { status: 'waitingToLoad', color: '#00ABBD' },
      { status: 'waitingToUnload', color: '#0099DD' },
      { status: 'unloading', color: '#7F8C8D      ' },
      { status: 'completed', color: '#026E81' },
      { status: 'miss', color: '#856C47' },
      { status: 'rejected', color: '#B52953' }
    ],
    customService: {
      ...shipmentService
    }
  }),
  computed: {
    bcGrid() {
      return helpers.findComponent(this, 'BcGrid')
    }
  },
  methods: {
    async getMeta() {
      const meta = await shipmentService.getMetaWithValidation()
      meta.permissions = [CrudAction.EXPORT, CrudAction.UPDATE]
      meta.fields.forEach((field) => {
        if (field.id === 'driver' || field.id === 'vehicle') {
          field.colDefProps = {
            editable: function (params) {
              return params.data.status == ShipmentStatusEnum.CONFIRMED
            }
          }
        }
      })
      meta.actions = [
        {
          title: this.$t('application.pages.shipment.acceptTravel'),
          className: 'fas fa-solid fa-check',
          enable: (row) => {
            return row.data.status === ShipmentStatusEnum.PUBLISHED
          },
          callback: async ({ node }) => {
            this.getAction(node.data.id, ShipmentActionEnum.ACCEPT)
          }
        },
        {
          title: this.$t('application.pages.shipment.refuseTravel'),
          className: 'fas fa-times',
          enable: (row) => {
            return row.data.status == ShipmentStatusEnum.PUBLISHED
          },
          callback: async ({ node }) => {
            this.getAction(node.data.id, ShipmentActionEnum.REJECT)
          }
        },
        {
          title: this.$t('application.pages.shipment.sendAbsenceTracking'),
          className: 'fas fa-exclamation-circle',
          enable: (row) => {
            if (row.data.status !== ShipmentStatusEnum.PUBLISHED) {
              if (row.data.absenceTrackingLabel !== undefined) {
                return false
              }
              return true
            }
          },
          callback: async ({ node }) => {
            this.throwAbsence(node.data)
          }
        }
      ]
      this.customService.getAll = async () => {
        try {
          return await shipmentService.getPendingShipmentsByUserCompany()
        } catch (e) {
          handleError(e)
        }
      }
      this.customService.export = async () => {
        try {
          return await shipmentService.getExportshipmentByUserCompany()
        } catch (e) {
          handleError(e)
        }
      }
      return meta
    },
    async throwAbsence(shipment) {
      this.shipmentAbsence = shipment
      this.showAbsenceDialog = true
    },
    async acceptSelectedShipments() {
      let bcGrid = helpers.findComponent(this, 'BcGrid')
      var selectedRows = bcGrid.aggridApi.getSelectedRows()
      var selectedIds = selectedRows.map((selectedRows) => selectedRows.id)
      try {
        await shipmentService.sendAcceptedShipmentIds(selectedIds)
        this.reloadGridData()
      } catch (e) {
        handleError(e)
      }
    },
    async acceptShipment(shipmentId) {
      this.showConfirm = true
      let idInArray = [shipmentId]
      try {
        await shipmentService.sendAcceptedShipmentIds(idInArray)
        this.reloadGridData()
      } catch (e) {
        handleError(e)
      }
    },
    async rejectShipment(shipmentId) {
      let idInArray = [shipmentId]
      try {
        await shipmentService.sendRejectedShipmentIds(idInArray)
        this.reloadGridData()
      } catch (e) {
        handleError(e)
      }
    },
    reloadGridData() {
      let bcCrudGrid = helpers.findComponent(this, 'BcCrudGrid')
      bcCrudGrid.onReloadHandler()
    },
    async getAction(selectedId, action) {
      this.nodeId = selectedId
      this.selectedAction = action
      this.confirmMessages = generateMessages(action)
      this.showConfirm = true
    },
    handleUpdateTable() {
      if (this.selectedAction == ShipmentActionEnum.ACCEPT) {
        return this.acceptShipment(this.nodeId)
      } else if (this.selectedAction == ShipmentActionEnum.REJECT) {
        return this.rejectShipment(this.nodeId)
      }
    },
    closeAbsenceDialog() {
      this.showAbsenceDialog = false
      this.reloadGridData()
    }
  },
  watch: {
    isAlert(newValue) {
      if (newValue) {
        notify.error({
          title: this.$t('application.pages.shipment.notifyTitle'),
          description: this.$t('application.pages.shipment.notifyWarning')
        })
      }
    }
  },
  async created() {
    const drivers = await driverService.getAll()
    const warningCellStyle = {
      border: '1px dashed red',
      color: 'white',
      backgroundColor: '#D2354F1a'
    }

    this.getMeta().then((meta) => {
      const vehicle = meta.fields.find((res) => res.id === 'vehicle')
      vehicle.colDefProps = {
        cellStyle: (params) => {
          let cellStyle = {}
          if (
            params.data.vehicle.text === '' &&
            params.data.status === ShipmentStatusEnum.CONFIRMED
          ) {
            this.isAlert = true
            cellStyle = warningCellStyle
          } else {
            cellStyle = { backgroundColor: 'transparent' }
          }
          return cellStyle
        }
      }

      const driver = meta.fields.find((res) => res.id === 'driver')
      driver.colDefProps = {
        cellStyle: (params) => {
          let cellStyle = {}
          if (
            params.data.driver.text === undefined &&
            params.data.status === ShipmentStatusEnum.CONFIRMED
          ) {
            this.isAlert = true
            cellStyle = warningCellStyle
          } else {
            cellStyle = { backgroundColor: 'transparent' }
          }
          return cellStyle
        }
      }

      const statusColors = this.statusDefinitions.reduce(
        (acc, { status, color }) => {
          acc[status] = color
          return acc
        },
        {}
      )

      const shipment = meta.fields.find((res) => res.id === 'status')
      shipment.colDefProps = {
        cellStyle: (params) => {
          const status = params.data.status
          const color = statusColors[status]

          return { color: `${color} !important` }
        }
      }

      const driverField = meta.fields.find((field) => field.id === 'driver')

      if (driverField) {
        const metaOptionsDriver = driverField.options

        const newDriverOptions = metaOptionsDriver.filter((option) =>
          drivers.some((driver) => driver.active && driver.id === option.value)
        )

        driverField.options = () => newDriverOptions
      }

      this.metadata = meta
    })
  }
}
</script>
<style lang="scss" scoped>
.button-header-container1 {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0.7rem;
}

::v-deep .main-toolbar {
  min-height: 0px !important;
}
</style>
